import React, { useState, useEffect, useRef } from 'react';
import Layout from '../../components/Layout';
import Mapbox from '../../mapbox'
// import { convertProvinceIdToText } from '../../mapbox/mapboxfn'
import LegendCard from '../../mapbox/LegendCard'
import Calculate from '../../util/calculate';
import Chart from '../../components/Chart';
import Sidetab from '../../components/Sidetab'
import ProgressProbView from '../../components/ProgressProbView';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TumbonAddr from '../../util/TumbonAddr'
import api from '../../util/api';
import '../index.css';
import { Modal, Divider, Select, Button } from 'antd';


import marker_obec from '/images/marker/dot-obec.svg'
import marker_dla from '/images/marker/dot-dla.svg'
import marker_bpp from '/images/marker/dot-bpp.svg'
import marker_onab from '/images/marker/dot-onab.svg'
import marker_opec from '/images/marker/dot-opec.svg'

import ComputerIcon from '@mui/icons-material/Computer';
import BoltIcon from '@mui/icons-material/Bolt';
import FactoryIcon from '@mui/icons-material/Factory';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffOutlinedIcon from '@mui/icons-material/WifiOffOutlined';
import OpacityIcon from '@mui/icons-material/Opacity';
import FormatColorResetIcon from '@mui/icons-material/FormatColorReset';

import axios from 'axios';

const { Option } = Select;

const MAP_TEXT = {
  "อยู่ในจังหวัดตามพระราชกฤษฎีกาจัดตั้งสถาบันวิจัยพัฒนาพื้นที่สูง (องค์การมหาชน) พ.ศ. 2548 เป็นพื้นที่ภูเขา  หรือพื้นที่ที่ที่มีความสูงกว่าระดับน้ำทะเล 500 เมตร ขึ้นไป": 'พื้นที่สูง',
  "พื้นราบ": 'พื้นที่ราบ',
  "บนเกาะ": 'พื้นที่เกาะ',
  "โรงเรียนที่ตั้งอยู่บนสันเขา เชิงเขา หรือพื้นที่ระหว่างหุบเขาที่มีความยากลำบากในการเดินทาง": 'พื้นที่เขา',
  "โรงเรียนที่เป็นพื้นที่สูงและชายแดน": 'พื้นที่ติดชายแดน',
}

const Province = (props) => {
  const [open, setOpen] = useState(true)
  const [tabvalue, setTabValue] = useState(0);
  const [filter, setFilter] = useState({
    view: 'province',
    year: "2567",
    region: "ทั้งหมด",
    province_name: "ทั้งหมด",
    city_name: null,
    tumbon_name: null,
  });
  const [data, setData] = useState([])
  const [dataProvince, setDataProvince] = useState({})
  const [dataSchool, setDataSchool] = useState({
    teacher_total: 0,
    total_student: 0,
    pc: 0,
    notebook: 0,
    school_has_electricity: 0,
    school_not_electricity: 0,
    school_has_electricity_service_area: 0,
    school_not_electricity_service_area: 0,

    school_has_internet: 0,
    school_not_internet: 0,

    school_has_water: 0,
    school_not_water: 0,

    agricultural_chemistry_air_problem: 0,
    factory_air_problem: 0,
    traffic_air_problem: 0,
    garbage_air_problem: 0,

    agricultural_chemistry_water_problem: 0,
    factory_water_problem: 0,
    traffic_water_problem: 0,
    garbage_water_problem: 0,

    agricultural_chemistry_noise_problem: 0,
    factory_noise_problem: 0,
    traffic_noise_problem: 0,
    garbage_noise_problem: 0,
  })
  const [data_map, setDataMap] = useState([])
  const [border_color] = useState(['#1b5e20', '#388e3c', '#66bb6a', '#a5d6a7', '#94a3b8',])
  const is_Click = useRef(false);
  const [modalSchool, setModalSchool] = useState({
    visible: false,
    resource: null
  });

  const [legend_data, setLegendData] = useState({
    title: '',
    subtitle: '',
    data: [],
    footer: ''
  })

  useEffect(async () => {
    let user_data = await api.getProfileDb()
    let province = null
    if (user_data.ok) {
      let user_province_id = '10'
      if (user_data.profile.profile.provinceId) {
        user_province_id = user_data.profile.profile.provinceId
        province = TumbonAddr.find(item => item.provinceid === user_data.profile.profile.provinceId).provincename
      }
      let data_json = await api.getInitData(`/province/call-province-${user_province_id}`)
      data_json = data_json.data
      data_json.y25672 = data_json.y25671
      filter.province_name = province
      setDataProvince(data_json)
      setFilter({ ...filter })
    }
  }, [])

  useEffect(() => {
    if (Object.values(dataProvince).length > 0) {
      let year = 'y' + filter.year + '2'
      let response = onProcessData({
        province: filter['province_name'],
        city: filter['city_name'],
        tumbon: filter['tumbon_name'],
        layer: filter.view
      }, convertMapContent(filter.view, dataProvince[year]))
      let data_filter = dataProvince[year]
      if (filter.tumbon_name !== null) {
        data_filter = [...new Set(dataProvince[year].filter(item => item.province_name === filter.province_name && item.city_name === filter.city_name && item.tumbon_name === filter.tumbon_name))]
      } else if (filter.city_name !== null) {
        data_filter = [...new Set(dataProvince[year].filter(item => item.province_name === filter.province_name && item.city_name === filter.city_name))]
      } else {
        data_filter = [...new Set(dataProvince[year].filter(item => item.province_name === filter.province_name))]
      }
      setDataSchool(mapSchoolData(data_filter))
      setDataMap([
        { id: 'border', layer: filter.view, data: response.data },
        { id: 'school_obec', layer: 'marker', data: data_filter.filter(item => item.sungkud === 'สพฐ.').map(item => ({ ...item, type: 'school_obec' })), icon: marker_obec },
        { id: 'school_dla', layer: 'marker', data: data_filter.filter(item => item.sungkud === 'อปท.').map(item => ({ ...item, type: 'school_dla' })), icon: marker_dla },
        { id: 'school_bpp', layer: 'marker', data: data_filter.filter(item => item.sungkud === 'ตชด.').map(item => ({ ...item, type: 'school_bpp' })), icon: marker_bpp },
        { id: 'school_opec', layer: 'marker', data: data_filter.filter(item => item.sungkud === 'สช.').map(item => ({ ...item, type: 'school_opec' })), icon: marker_opec },
        { id: 'school_onab', layer: 'marker', data: data_filter.filter(item => item.sungkud === 'พศ.').map(item => ({ ...item, type: 'school_onab' })), icon: marker_onab }
      ])
      let data_legend = [
        { name: 'จำนวนโรงเรียน', value: response.data.reduce((acc, cur) => acc + cur.school, 0), unit: 'โรงเรียน' },
      ]
      setLegendData({
        ...legend_data,
        title: filter.province_name,
        subtitle: filter.city_name,
        data: data_legend,
      })
    }
  }, [filter, dataProvince])

  const mapSchoolData = (data) => {
    let result = {
      teacher_total: 0,
      total_student: 0,
      pc: 0,
      notebook: 0,
      school_has_electricity: 0,
      school_not_electricity: 0,
      school_has_electricity_service_area: 0,
      school_not_electricity_service_area: 0,

      school_has_internet: 0,
      school_not_internet: 0,

      school_has_water: 0,
      school_not_water: 0,

      agricultural_chemistry_air_problem: 0,
      factory_air_problem: 0,
      traffic_air_problem: 0,
      garbage_air_problem: 0,

      agricultural_chemistry_water_problem: 0,
      factory_water_problem: 0,
      traffic_water_problem: 0,
      garbage_water_problem: 0,

      agricultural_chemistry_noise_problem: 0,
      factory_noise_problem: 0,
      traffic_noise_problem: 0,
      garbage_noise_problem: 0,

      sum_school_sizeXL: 0,
      sum_school_sizeX: 0,
      sum_school_sizeL: 0,
      sum_school_sizeS: 0,
    }
    data.forEach(x => {
      result[`teacher_total`] += x.total_teacher
      result[`total_student`] += x.total_student
      result[`pc`] += (
        x.desktop_computer_for_manage_can_be_used +
        x.desktop_computer_for_learning_can_be_used
      )
      result[`notebook`] += (
        x.notebook_for_manage_can_be_used +
        x.notebook_for_learning_can_be_used
      )
      result[`school_has_electricity`] += x.school_has_electricity
      result[`school_not_electricity`] += (x.sungkud === 'สพฐ.' ? x.school_not_electricity : 0)
      result[`school_has_electricity_service_area`] += x.school_has_electricity_service_area
      result[`school_not_electricity_service_area`] += (x.sungkud === 'สพฐ.' ? x.school_not_electricity_service_area : 0)
      if (x.internet_no || x.internet_unspecified) {
        result[`school_not_internet`] += (x.sungkud === 'สพฐ.' ? 1 : 0)
      } else {
        result[`school_has_internet`] += 1
      }
      if (x.water_unspecified) {
        result[`school_not_water`] += (x.sungkud === 'สพฐ.' ? 1 : 0)
      } else {
        result[`school_has_water`] += 1
      }
      result[`agricultural_chemistry_air_problem`] += x.agricultural_chemistry_air_problem
      result[`factory_air_problem`] += x.factory_air_problem
      result[`traffic_air_problem`] += x.traffic_air_problem
      result[`garbage_air_problem`] += x.garbage_air_problem

      result[`agricultural_chemistry_water_problem`] += x.agricultural_chemistry_water_problem
      result[`factory_water_problem`] += x.factory_water_problem
      result[`traffic_water_problem`] += x.traffic_water_problem
      result[`garbage_water_problem`] += x.garbage_water_problem

      result[`agricultural_chemistry_noise_problem`] += x.agricultural_chemistry_noise_problem
      result[`factory_noise_problem`] += x.factory_noise_problem
      result[`traffic_noise_problem`] += x.traffic_noise_problem
      result[`garbage_noise_problem`] += x.garbage_noise_problem

      result['sum_school_sizeXL'] += (x.school_size === 'ขนาดเล็ก' ? 1 : 0)
      result['sum_school_sizeX'] += (x.school_size === 'ขนาดกลาง' ? 1 : 0)
      result['sum_school_sizeL'] += (x.school_size === 'ขนาดใหญ่' ? 1 : 0)
      result['sum_school_sizeS'] += (x.school_size === 'ขนาดใหญ่พิเศษ' ? 1 : 0)
    })
    return (result)
  }

  const convertMapContent = (layer, data) => {
    let res = []
    const province = [...new Set(TumbonAddr.filter(item => item.provincename === filter.province_name).map(item => item.provincename))].map(item => { return { province: item, school: 0 } });
    const city = [...new Set(TumbonAddr.filter(item => item.provincename === filter.province_name).map(item => item.provincename + '_' + item.cityname))].map(item => { return { province: item.split('_')[0], city: item.split('_')[1], school: 0 } });
    const tumbon = [...new Set(TumbonAddr.filter(item => item.provincename === filter.province_name).map(item => item.provincename + '_' + item.cityname + '_' + item.tumbonname))].map(item => { return { province: item.split('_')[0], city: item.split('_')[1], tumbon: item.split('_')[2], school: 0 } });
    data.forEach((item) => {
      if (layer === 'country') {
        let index = province.findIndex(ele => ele.province === item.province_name)
        if (index !== -1) {
          !province[index]['school'] ? province[index]['school'] = 1 : province[index]['school'] += 1
        }
        res = province
      } else if (layer === 'province') {
        let index = city.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name)
        if (index !== -1) {
          !city[index]['school'] ? city[index]['school'] = 1 : city[index]['school'] += 1

        }
        res = city
      } else if (layer === 'city') {
        let index = tumbon.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name && ele.tumbon === (item.province_id === '10' ? 'แขวง' + item.tumbon_name : item.tumbon_name))
        if (index !== -1) {
          !tumbon[index]['school'] ? tumbon[index]['school'] = 1 : tumbon[index]['school'] += 1
        }
        res = tumbon
      } else if (layer === 'tumbon') {
        let index = tumbon.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name && ele.tumbon === (item.province_id === '10' ? 'แขวง' + item.tumbon_name : item.tumbon_name))
        if (index !== -1) {
          !tumbon[index]['school'] ? tumbon[index]['school'] = 1 : tumbon[index]['school'] += 1
        }

        res = tumbon
      }
    })
    if (filter.region !== 'ทั้งหมด' && filter.view === 'country') res = res.filter(item => item.region === filter.region)
    return res
  }

  const onProcessData = (filter, data) => {
    const _genColorRange = (color_set, value) => {
      color_set = color_set.sort((a, b) => b.value - a.value)
      let color = ''
      for (let i in color_set) {
        if (value > color_set[i].value) {
          color = color_set[i].color
          break;
        }
      }
      return color
    }

    let result = []
    data.forEach(element => {
      if (filter.province && filter.province !== 'ทั้งหมด' && !filter.city && !filter.tumbon) {
        if (filter.province === element.province) {
          result.push(element)
        }
      } else if (filter.province && filter.city && !filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city) {
          result.push(element)
        }
      } else if (filter.province && filter.city && filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city && filter.tumbon === element.tumbon) {
          result.push(element)
        }
      } else {
        result.push(element)
      }
    })

    result.map(element => {
      if (!isFinite(element['school'] / element['school'])) {
        element['percent'] = 0
      } else {
        element['percent'] = (((element['school']) / result.reduce((acc, cur) => acc + (cur.school), 0)) * 100)
      }
      return element
    })
    let data_color = [
      // { color: '#FF9A7B', value: (average(result) * 1.5), count: 0, text: `มากกว่า ${(average(result) * 1.5).toFixed(2)}%` },
      // { color: '#FFAE93', value: (average(result)), count: 0, text: `${average(result).toFixed(2)}% - ${(average(result) * 1.5).toFixed(2)}%` },
      // { color: '#FECAB5', value: (average(result) / 2), count: 0, text: `${(average(result) / 2).toFixed(2)}% - ${average(result).toFixed(2)}%` },
      // { color: '#FFEEE7', value: (0), count: 0, text: `น้อยกว่า ${(average(result) / 2).toFixed(2)}%` },

      { color: border_color[0], value: Calculate.Percentile(90, result.map(item => item.school)), count: 0, text: `มาก` },
      { color: border_color[1], value: Calculate.Percentile(50, result.map(item => item.school)), count: 0, text: `ปานกลาง` },
      { color: border_color[2], value: Calculate.Percentile(10, result.map(item => item.school)), count: 0, text: `ปานกลาง` },
      { color: border_color[3], value: 0, count: 0, text: `น้อย` },
      { color: border_color[4], value: -100, count: 0, text: `น้อย` },
    ]
    result.forEach(element => {
      element['color'] = _genColorRange(data_color, (element.school))
      // element['opacity'] = 0.5
      element['percent'] = parseFloat(element['percent'].toFixed(2))
    })
    let res = { data: result, notic: data_color }
    return res
  }

  const onclickBorder = (layer, filterBorder) => {
    // if (layer === 'tumbon') return;
    if (!is_Click.current) {
      is_Click.current = true
      let _filter = { ...filter }
      if (layer === 'province' && filterBorder.province) {
        _filter['view'] = 'province'
        _filter['year'] = filterBorder.year
        _filter['province_name'] = filterBorder.province
        _filter['city_name'] = null
        _filter['tumbon_name'] = null
      } else if (layer === 'city' && filterBorder.city) {
        _filter['view'] = 'city'
        _filter['year'] = filterBorder.year
        _filter['province_name'] = filterBorder.province
        _filter['city_name'] = filterBorder.city
        _filter['tumbon_name'] = null
      } else if (layer === 'tumbon' && filterBorder.tumbon) {
        _filter['view'] = 'tumbon'
        _filter['year'] = filterBorder.year
        _filter['province_name'] = filterBorder.province
        _filter['city_name'] = filterBorder.city
        _filter['tumbon_name'] = filterBorder.tumbon
      }
      setFilter(_filter)
      setTimeout(() => {
        is_Click.current = false
      }, 1000)
    }
  }

  const onClickMarker = (property) => {
    if (!is_Click.current) {
      is_Click.current = true
      setModalSchool({
        visible: true,
        resource: 'school',
        ...property
      })
      setTimeout(() => {
        is_Click.current = false
      }, 1000)
    }
  }

  const onBack = () => {
    let _filter = { ...filter }
    if (_filter.view === 'province') return;
    if (_filter.view === 'city') {
      _filter['view'] = 'province'
      _filter['city_name'] = null
      _filter['tumbon_name'] = null
    } else if (_filter.view === 'tumbon') {
      _filter['view'] = 'city'
      _filter['tumbon_name'] = null
    }
    setFilter(_filter)
  }

  let tab_content = [
    {
      title: '',
      content: <div className='block w-full px-1 lg-px-16'>
        <div className='flex gap-4 justify-center'>
          <Select
            className='new-design-select'
            placeholder="ปีการศึกษา"
            showSearch
            value={filter.year}
            onChange={(val) => {
              setFilter({
                ...filter,
                year: val
              })
            }}
            style={{ width: 120, margin: 4, fontFamily: 'Kanit' }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {
              // ['2/2565', '1/2565', '2/2564', '1/2564', '2/2563', '1/2563', '2/2562', '1/2562', '2/2561']
              ['2567','2566','2565', '2564', '2563'].map(x => {
                return <Option value={x}>{x}</Option>
              })
            }
          </Select>
          <Select
            className='new-design-select'
            placeholder="อำเภอ"
            showSearch
            value={filter.city_name}
            onChange={(val) => {
              if (val === 'ทั้งหมด') {
                setFilter({
                  ...filter,
                  view: 'province',
                  city_name: null,
                  tumbon_name: null,
                })
              } else {
                setFilter({
                  ...filter,
                  view: 'city',
                  city_name: val,
                  tumbon_name: null,
                })
              }
            }}
            style={{ width: 120, margin: 4, fontFamily: 'Kanit' }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={'ทั้งหมด'}>{'ทั้งหมด'}</Option>
            {
              [, ...new Set(TumbonAddr.filter(item => item.provincename === filter.province_name).map(x => x.cityname))]
                .filter(x => x !== 'ไม่ระบุ')
                .sort()
                .map(x => <Option value={x}>{x}</Option>)
            }
          </Select>
          <Select
            className='new-design-select'
            placeholder="ตำบล"
            showSearch
            disabled={filter.city_name === 'ทั้งหมด' ? true : false}
            value={filter.tumbon_name}
            onChange={(val) => {
              if (val === 'ทั้งหมด') {
                setFilter({
                  ...filter,
                  view: 'city',
                  tumbon_name: null,
                })
              } else {
                setFilter({
                  ...filter,
                  view: 'tumbon',
                  tumbon_name: val
                })
              }
            }}
            style={{ width: 120, margin: 4, fontFamily: 'Kanit' }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={'ทั้งหมด'}>{'ทั้งหมด'}</Option>
            {
              filter.city_name !== 'ทั้งหมด' &&
              [, ...new Set(TumbonAddr.filter(item => item.provincename === filter.province_name && filter.city_name === item.cityname).map(x => x.tumbonname))]
                .filter(x => x !== 'ไม่ระบุ')
                .sort()
                .map(x => <Option value={x}>{x}</Option>)
            }
          </Select>
        </div>
        {/* <div className='text-center my-4'>
          <h1 className='text-3xl font-bold'>จำนวนเด็กและเยาวชนวัยเรียน</h1>
          <h2 className='text-2xl text-[#003171] font-bold'>{`"${filter.province_name}"`}</h2>

          <h1 className='text-5xl text-[#1611FF] font-bold'>{data.child_total ? data.child_total.toLocaleString('en-US') : 0}</h1>
        </div> */}
        <div className='flex flex-col w-full mx-auto gap-4'>
          <div className='grid grid-cols-10 gap-2'>
            <div className='col-span-10 md:col-span-4'>
              {/* <div className='w-full rounded-md shadow-md bg-white py-4 my-2'>
                <div className='flex flex-col text-center'>
                  <span className='text-xl font-bold'>อัตราส่วนครูต่อนักเรียน</span>
                  <span className='text-[#003171] text-2xl font-bold'>{`1 : ${Math.ceil(dataSchool.total_student / dataSchool.teacher_total)}`}</span>
                </div>
              </div>
              <div className='w-full rounded-md shadow-md bg-white py-4 my-2'>
                <div className='flex flex-col text-center'>
                  <span className='text-xl font-bold'>จำนวนครูและบุคลากรทั้งหมด</span>
                  <span className='text-[#048967] text-2xl font-bold'>{dataSchool.teacher_total.toLocaleString('en-Us')}</span>
                  <span>คน</span>
                </div>
              </div> */}
              <div className='flex w-full h-full rounded-md shadow-md bg-white py-4 px-2'>
                <div className='flex flex-col w-full items-center'>
                  <div className='flex flex-row mx-auto'>
                    <ComputerIcon className='my-auto' fontSize="large" />
                    <p className='ml-2 my-auto text-2xl font-bold'>คอมพิวเตอร์</p>
                  </div>
                  <div className='text-center mt-4'>
                    <p className='text-xl font-bold mb-1'>จำนวนคอมพิวเตอร์ทั้งหมด</p>
                    <p>จำนวน<span className='text-2xl text-[#003F88] font-bold mx-2'>{(dataSchool.pc + dataSchool.notebook).toLocaleString('en-Us')}</span>เครื่อง</p>
                  </div>
                  <div className='text-center mt-4'>
                    <p className='text-xl font-bold mb-1'>คอมพิวเตอร์ตั้งโต๊ะ</p>
                    <p>จำนวน<span className='text-2xl text-[#003F88] font-bold mx-2'>{dataSchool.pc.toLocaleString('en-Us')}</span>เครื่อง</p>
                  </div>
                  <div className='text-center mt-4'>
                    <p className='text-xl font-bold mb-1'>Notebook</p>
                    <p>จำนวน<span className='text-2xl text-[#003F88] font-bold mx-2'>{dataSchool.notebook.toLocaleString('en-Us')}</span>เครื่อง</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex flex-col col-span-10 md:col-span-3 gap-2'>
              <div className='w-full rounded-md shadow-md bg-white flex flex-col py-4 px-2'>
                <div className='flex flex-row ml-2'>
                  <BoltIcon className='my-auto text-[#fbbf24]' />
                  <p className='ml-1 my-auto text-lg font-bold'>มีไฟฟ้าใช้</p>
                </div>
                <Chart
                  options={{
                    title: `${((dataSchool.school_has_electricity / (dataSchool.sum_school_sizeS + dataSchool.sum_school_sizeX + dataSchool.sum_school_sizeL + dataSchool.sum_school_sizeXL)) * 100).toFixed(2)} %`,
                    type: 'donut',
                  }}
                  containerProps={{ style: { height: "250px" } }}
                  style={{ width: '150px', height: '260px' }}
                  data={[{
                    type: 'pie',
                    name: 'Browser share',
                    innerSize: '50%',
                    data: [
                      {
                        name: 'มีไฟฟ้าใช้',
                        color: '#047857',
                        y: dataSchool.school_has_electricity,
                      },
                      {
                        name: '',
                        color: '#d4d4d8',
                        y: (dataSchool.sum_school_sizeS + dataSchool.sum_school_sizeX + dataSchool.sum_school_sizeL + dataSchool.sum_school_sizeXL) - dataSchool.school_has_electricity,
                      }
                    ]
                  }]}
                />
                <p className='text-center text-lg m-0'>จำนวน<span className='text-2xl font-bold mx-2'>{dataSchool.school_has_electricity.toLocaleString('en-Us')}</span>โรงเรียน</p>
              </div>
              <div className='w-full rounded-md shadow-md bg-white flex flex-col py-4 px-2'>
                <div className='flex flex-row ml-2'>
                  <FactoryIcon className='my-auto text-[#16a34a]' />
                  <p className='ml-1 my-auto text-lg font-bold'>อยู่ในเขตบริการไฟฟ้า</p>
                </div>
                <Chart
                  options={{
                    title: `${((dataSchool.school_has_electricity_service_area / (dataSchool.sum_school_sizeS + dataSchool.sum_school_sizeX + dataSchool.sum_school_sizeL + dataSchool.sum_school_sizeXL)) * 100).toFixed(2)} %`,
                    type: 'donut',
                  }}
                  containerProps={{ style: { height: "250px" } }}
                  style={{ width: '150px', height: '260px' }}
                  data={[{
                    type: 'pie',
                    name: 'Browser share',
                    innerSize: '50%',
                    data: [
                      {
                        name: 'อยู่ในเขตบริการไฟฟ้า',
                        color: '#047857',
                        y: dataSchool.school_has_electricity_service_area,
                      },
                      {
                        name: '',
                        color: '#d4d4d8',
                        y: (dataSchool.sum_school_sizeS + dataSchool.sum_school_sizeX + dataSchool.sum_school_sizeL + dataSchool.sum_school_sizeXL) - dataSchool.school_has_electricity_service_area,
                      }
                    ]
                  }]}
                />
                <p className='text-center text-lg m-0'>จำนวน<span className='text-2xl font-bold mx-2'>{dataSchool.school_has_electricity_service_area.toLocaleString('en-Us')}</span>โรงเรียน</p>
                <p className='text-center text-sm m-0'>เขตบริการไฟฟ้าส่วนภูมิภาค/นครหลวง</p>
              </div>
            </div>
            <div className='flex flex-col col-span-10 md:col-span-3 gap-2'>
              <div className='w-full rounded-md shadow-md bg-white flex flex-col py-4 px-2'>
                <div className='flex flex-row ml-2'>
                  <BoltIcon className='my-auto text-[#ef4444]' />
                  <p className='ml-1 my-auto text-lg font-bold'>ไม่มีไฟฟ้าใช้</p>
                </div>
                <Chart
                  options={{
                    title: `${((dataSchool.school_not_electricity / (dataSchool.sum_school_sizeS + dataSchool.sum_school_sizeX + dataSchool.sum_school_sizeL + dataSchool.sum_school_sizeXL)) * 100).toFixed(2)} %`,
                    type: 'donut',
                  }}
                  containerProps={{ style: { height: "250px" } }}
                  style={{ width: '150px', height: '260px' }}
                  data={[{
                    type: 'pie',
                    name: 'Browser share',
                    innerSize: '50%',
                    data: [
                      {
                        name: 'ไม่มีไฟฟ้าใช้',
                        color: '#ef4444',
                        y: dataSchool.school_not_electricity,
                      },
                      {
                        name: '',
                        color: '#d4d4d8',
                        y: (dataSchool.sum_school_sizeS + dataSchool.sum_school_sizeX + dataSchool.sum_school_sizeL + dataSchool.sum_school_sizeXL) - dataSchool.school_not_electricity,
                      }
                    ]
                  }]}
                />
                <p className='text-center text-lg m-0'>จำนวน<span className='text-2xl font-bold mx-2'>{dataSchool.school_not_electricity.toLocaleString('en-Us')}</span>โรงเรียน</p>
              </div>
              <div className='w-full rounded-md shadow-md bg-white flex flex-col py-4 px-2'>
                <div className='flex flex-row ml-2'>
                  <FactoryIcon className='my-auto text-[#b91c1c]' />
                  <p className='ml-1 my-auto text-lg font-bold'>อยู่นอกเขตบริการไฟฟ้า</p>
                </div>
                <Chart
                  options={{
                    title: `${((dataSchool.school_not_electricity_service_area / (dataSchool.sum_school_sizeS + dataSchool.sum_school_sizeX + dataSchool.sum_school_sizeL + dataSchool.sum_school_sizeXL)) * 100).toFixed(2)} %`,
                    type: 'donut',
                  }}
                  containerProps={{ style: { height: "250px" } }}
                  style={{ width: '150px', height: '260px' }}
                  data={[{
                    type: 'pie',
                    name: 'Browser share',
                    innerSize: '50%',
                    data: [
                      {
                        name: '1',
                        color: '#ef4444',
                        y: dataSchool.school_not_electricity_service_area,
                      },
                      {
                        name: '2',
                        color: '#d4d4d8',
                        y: (dataSchool.sum_school_sizeS + dataSchool.sum_school_sizeX + dataSchool.sum_school_sizeL + dataSchool.sum_school_sizeXL) - dataSchool.school_not_electricity_service_area,
                      }
                    ]
                  }]}
                />
                <p className='text-center text-lg m-0'>จำนวน<span className='text-2xl font-bold mx-2'>{dataSchool.school_not_electricity_service_area.toLocaleString('en-Us')}</span>โรงเรียน</p>
                <p className='text-center text-sm m-0'>เขตบริการไฟฟ้าส่วนภูมิภาค/นครหลวง</p>
              </div>
            </div>
          </div>
          <div className='w-full rounded-md shadow-md bg-white py-4 px-2'>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-2'>
              <div className='col-span-1 my-auto'>
                <p className='text-xl font-bold text-center'>ข้อมูลอินเตอร์เน็ต</p>
              </div>
              <div className='col-span-1 text-center'>
                <span className='flex my-auto justify-center'><WifiIcon className='my-auto text-[#16a34a]' fontSize="large" /><span className='text-xl ml-2 my-auto'>มีเข้าถึงอินเตอร์เน็ต</span></span>
                <span className='text-2xl text-[#003F88] font-bold mr-2'>{dataSchool.school_has_internet.toLocaleString('en-Us')}</span> โรงเรียน
              </div>
              <div className='col-span-1 text-center'>
                <span className='flex my-auto justify-center'><WifiOffOutlinedIcon className='my-auto text-[#ef4444]' fontSize="large" /><span className='text-xl ml-2 my-auto'>ไม่มีการเข้าถึงอินเตอร์เน็ต</span></span>
                <span className='text-2xl text-[#003F88] font-bold mr-2'>{dataSchool.school_not_internet.toLocaleString('en-Us')}</span> โรงเรียน
              </div>
            </div>
          </div>
          <div className='w-full rounded-md shadow-md bg-white py-4 px-2'>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-2'>
              <div className='col-span-1 my-auto'>
                <p className='text-xl font-bold text-center'>ข้อมูลแหล่งน้ำใช้</p>
              </div>
              <div className='col-span-1 text-center'>
                <span className='flex my-auto justify-center'><OpacityIcon className='my-auto text-[#16a34a]' fontSize="large" /><span className='text-xl ml-2 my-auto'>มีน้ำใช้</span></span>
                <span className='text-2xl text-[#003F88] font-bold mr-2'>{dataSchool.school_has_water.toLocaleString('en-Us')}</span> โรงเรียน
              </div>
              <div className='col-span-1 text-center'>
                <span className='flex my-auto justify-center'><FormatColorResetIcon className='my-auto text-[#ef4444]' fontSize="large" /><span className='text-xl ml-2 my-auto'>ไม่มีน้ำใช้</span></span>
                <span className='text-2xl text-[#003F88] font-bold mr-2'>{dataSchool.school_not_water.toLocaleString('en-Us')}</span> โรงเรียน
              </div>
            </div>
          </div>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-2'>
            <div className='col-span-1'>
              <div className='w-full rounded-md shadow-md bg-white justify-between'>
                <ProgressProbView
                  agricultural={dataSchool.agricultural_chemistry_air_problem}
                  chemical={dataSchool.factory_air_problem}
                  traffic={dataSchool.traffic_air_problem}
                  sewage={dataSchool.garbage_air_problem}
                  total={
                    dataSchool.agricultural_chemistry_air_problem + dataSchool.factory_air_problem + dataSchool.traffic_air_problem + dataSchool.garbage_air_problem
                  }
                  category="อากาศ"
                  title="ปัญหาสภาพอากาศ"
                />
              </div>
            </div>
            <div className='col-span-1'>
              <div className='w-full rounded-md shadow-md bg-white justify-between'>
                <ProgressProbView
                  agricultural={dataSchool.agricultural_chemistry_noise_problem}
                  chemical={dataSchool.factory_noise_problem}
                  traffic={dataSchool.traffic_noise_problem}
                  sewage={dataSchool.garbage_noise_problem}
                  total={
                    dataSchool.agricultural_chemistry_noise_problem + dataSchool.factory_noise_problem + dataSchool.traffic_noise_problem + dataSchool.garbage_noise_problem
                  }
                  category="น้ำ"
                  title="ปัญหาสภาพน้ำ"
                />
              </div>
            </div>
            <div className='col-span-1'>
              <div className='w-full rounded-md shadow-md bg-white justify-between'>
                <ProgressProbView
                  agricultural={dataSchool.agricultural_chemistry_water_problem}
                  chemical={dataSchool.factory_water_problem}
                  traffic={dataSchool.traffic_water_problem}
                  sewage={dataSchool.garbage_water_problem}
                  total={
                    dataSchool.agricultural_chemistry_water_problem + dataSchool.factory_water_problem + dataSchool.traffic_water_problem + dataSchool.garbage_water_problem
                  }
                  category="เสียง"
                  title="ปัญหาด้านเสียง"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

    },
  ]

  return (
    <Layout Role={['partner', 'eef', 'partner_province']} isHideFooter>
      <Modal
        width={'1000px'}
        bodyStyle={{ borderRadius: 24 }}
        title={null}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        visible={modalSchool.visible}
        centered={true}
        CancelText={'ปิด'}
        onCancel={() => {
          setModalSchool({ visible: false })
        }}
      >
        <div className='text-3xl'>โรงเรียน{modalSchool?.hostname} <span className='text-xl'></span></div>
        <div className='text-xl'>ที่อยู่ ตำบล{modalSchool?.tumbon_name} อำเภอ{modalSchool?.city_name} จังหวัด{modalSchool?.province_name}</div>
        <Divider style={{ margin: 8 }} />
        {/* <div className='text-3xl m-2'>ได้รับทุนจากโครงการทุนเสมอภาค {modalSchool?.total_exjon} คน</div> */}

        <div className='grid grid-cols-12 gap-4 '>
          <div className='col-span-12 lg:col-span-6 flex-col'>
            <div className='relative w-full '>
              <div className='w-full flex flex-col rounded-lg p-2'>
                <div className='text-xl text-green-500 '>ข้อมูลพื้นฐาน</div>
                <div className='text-lg '>สังกัด :  {modalSchool?.sungkud} </div>
                <div className='text-lg '>ขนาดโรงเรียน :  {modalSchool?.school_size} </div>
                <div className='text-lg '>ลักษณะภูมิประเทศ :  {modalSchool?.geographic_location ? MAP_TEXT[modalSchool?.geographic_location] : '-'} </div>
              </div>
            </div>
          </div>
          <div className='col-span-12 lg:col-span-6 flex-col'>
            <div className='relative w-full '>
              <div className='w-full flex flex-col rounded-lg p-2'>
                <div className='text-xl text-green-500 '>ข้อมูลทรัพยากร</div>
                {modalSchool?.hostname &&
                  <>
                    <div className='text-lg '>จำนวนคอมพิวเตอร์ตั้งโต๊ะ :  {modalSchool?.desktop_computer_for_learning_can_be_used + modalSchool?.desktop_computer_for_manage_can_be_used}  </div>
                    <div className='text-lg '>จำนวนคอมพิเวเตอร Notebook : {modalSchool?.notebook_for_learning_can_be_used + modalSchool?.notebook_for_manage_can_be_used}  </div>
                    <div className='text-lg '>ไฟฟ้า : {modalSchool?.school_has_electricity ? 'มีไฟฟ้า' : 'ไม่มีไฟฟ้า'}  </div>
                    <div className='text-lg '>เขตบริการไฟฟ้า : {modalSchool?.school_has_electricity_service_area ? 'เขตบริการไฟฟ้า' : 'อยู่นอกเขตบริการไฟฟ้า'}  </div>
                    <div className='text-lg '>การเข้าถึง Internet : {modalSchool?.school_has_internet ? 'มีเข้าถึงอินเตอร์เน็ต' : 'ไม่มีการเข้าถึงอินเตอร์เน็ต'}  </div>
                    <div className='text-lg '>ข้อมูลแหล่งน้ำใช้ : {modalSchool?.school_has_water ? 'มี' : 'ไม่มี'}  </div>
                    <div className='text-lg '>ปัญหาอากาศ :  {
                      (
                        modalSchool.agricultural_chemistry_air_problem +
                        modalSchool.factory_air_problem +
                        modalSchool.traffic_air_problem +
                        modalSchool.garbage_air_problem
                      ) > 0 ?
                        "มี" : "ไม่มี"
                    }
                    </div>
                    <div className='text-lg '>ปัญหาน้ำ :  {
                      (
                        modalSchool.agricultural_chemistry_water_problem +
                        modalSchool.factory_water_problem +
                        modalSchool.traffic_water_problem +
                        modalSchool.garbage_water_problem
                      ) > 0 ?
                        "มี" : "ไม่มี"
                    }
                    </div>
                    <div className='text-lg '>ปัญหาเสียง :  {
                      (
                        modalSchool.agricultural_chemistry_noise_problem +
                        modalSchool.factory_noise_problem +
                        modalSchool.traffic_noise_problem +
                        modalSchool.garbage_noise_problem
                      ) > 0 ?
                        "มี" : "ไม่มี"
                    }
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
        </div>

      </Modal>

      <div className='relative overflow-hidden'>
        <div className='w-[100vw] h-[100vh]'>
          <Mapbox
            id={'map-cct'}
            filter={filter}
            data={data_map}
            lng={99}
            lat={13.4}
            zoom={5.2}
            className="fullmap-cct-container"
            style={"border_basic"}
            openSideTab={open}
            notic_title={'จำนวนโรงเรียนในประเทศไทย'}
            onclickBorder={onclickBorder}
            onClickMarker={onClickMarker}
          />
          <div className='absolute top-[90px] left-2 z-1'>
            <LegendCard {...legend_data} />
            <div className='justify-left mt-2 mx-auto'>
              {filter.view !== "province" ? <button class="bg-[#fb923c] hover:bg-[#f97316] border-8 border-[#ffedd5] text-white font-bold p-2 rounded-full" onClick={() => onBack()}>
                <ArrowBackIcon sx={{ fontSize: 18, color: 'white' }} /> ย้อนกลับ
              </button> : <></>}
            </div>
          </div>
          <div className='absolute bottom-[50px] md:bottom-[10px] left-[100px] z-1 flex'>
            <div className='h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
              <span className='font-bold center mx-1 mb-2'>สัดส่วนจำนวนโรงเรียน</span>
              <div className='flex'>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#1b5e20]`}>
                  </div>
                  <div class="font-bold text-left">
                    มาก
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#388e3c]`}>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#66bb6a]`}>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#a5d6a7]`}>
                  </div>
                  <div class="font-bold text-right">
                    น้อย
                  </div>
                </div>
                <div className='ml-4  flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#94a3b8]`}>
                  </div>
                  <div class="font-bold text-center">
                    ไม่มีข้อมูล
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='absolute bottom-[10px] left-[470px] z-1'>
            <div className='h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
              <span className='font-bold center mx-1 mb-2'>ประเภทสถานศึกษาตามสังกัด</span>
              <div class="flex flex-row">
                <div class="flex flex-row text-center mr-2">
                  <img src={marker_obec} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                  <span className='center m-auto'>สพฐ.</span>
                </div>
                <div class="flex flex-row text-center mr-2">
                  <img src={marker_dla} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                  <span className='center m-auto'>อปท.</span>
                </div>
                <div class="flex flex-row text-center mr-2">
                  <img src={marker_bpp} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                  <span className='center m-auto'>ตชด.</span>
                </div>
                <div class="flex flex-row text-center mr-2">
                  <img src={marker_onab} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                  <span className='center m-auto'>พศ.</span>
                </div>
                <div class="flex flex-row text-center mr-2">
                  <img src={marker_opec} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                  <span className='center m-auto'>สช.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Sidetab
          title={<>สถานการณ์ด้านการศึกษาและการทำงาน <br />ของ กสศ. ในพื้นที่ของท่าน</>}
          tab={tab_content}
          open={open}
          tabvalue={tabvalue}
          setOpen={setOpen}
          titleRef=''
          subjectTitleRef='*ประมวลผล ณ เดือนสิงหาคม 2567'
        // handleChangeTab={handleChangeTab}
        />
      </div>
    </Layout>
  )
}

export default Province
